<div class="maincontentarea mt20">
    <div class="container">
        <div class="row">
            <div class="col-md-12 contest-rules-text">
                    <h2>RULES AND REGULATIONS</h2>
                    <h2>PRIDEPLEX SHORT FILM COMPETITION - NE </h2>
                    <h2>CATEGORY: SHORT FILM COMPETITION</h2>
                <p>This is the first set of rules and regulations, labelled as SHORT FILM COMPETITION version 1.0</p>

                <label>GENERAL RULES AND REGULATIONS:</label>
                <ul>
                    <li>There are only one methods of submitting films to the film festival- online submission through Prideplex. Please read the relevant one carefully before filling the application form.</li>
                    <li>An applicant filling out this form, should either be the owner of the film's rights or have permission from the owner. Even if not the owner, the sole responsibility lies upon the applicant for making sure the information given is true. Any discrepancies found in the form; the entry would be disqualified. It will otherwise be assumed that the film has the requisite authority from the owner and will be screened at PRIDEPLEX.</li>
                    <li>For submission of films, there should be a Primary Contact Person who will be the main point of contact for all future communications regarding their submission to the festival. The email address and phone number should be correctly filled up for further communication and be ensured that the Primary Contact Person is easily reachable at all times.</li>
                    <li>Completed applications need to be submitted by the given deadline. The competition will not take responsibility for the exclusion of films if complete information is not submitted by the deadlines.</li>
                    <li>By submitting the application form, the applicant confirms that they have read and understood the rules and regulations listed, that they are legally authorized to submit the film to Prideplex short film competition - ne 2025 and that the information given by the applicant is true and correct. Further, that the applicant protects PSFC - NE against any claims to the contrary.</li>
                    <li>The cost of shipping an entry to the contest must be paid by the applicant.</li>
                    <li>All disputes shall be subject to the exclusive jurisdiction of the courts of Guwahati only.</li>
                    <li>Prideplex reserve the right of streaming selected short films on Prideplex OTT</li>
                </ul>

                <label>SHORT FILM CRITERIA:</label>
                <ul>
                    <li>PSFC - NE  is a part of PRIDEPLEX and is North East India  inclusive.</li>
                    <li>
                        PSFC - NE is open to any films fulfilling any of the following criteria:
                        <ul>
                            <ol>- Director(s) from Northeast India</ol>
                            <ol>- Featuring stories/ topics related to Northeast India.</ol>
                        </ul>
                    </li>
                    <li>The maximum run time duration of a short film must be 32 minutes including credits.</li>
                    <li>Subtitles in English is a must, without subtitles it will be disqualified. If the original version of the film is in English, English subtitles are still required. Cost of subtitling is the sole responsibility of the applicants.</li>
                    <li>Dubbed versions of films in other languages are not eligible for submission.</li>
                    <li>Film applying to the festival do not require a certification from the Central Board of Film Certification (CBFC) of India.</li>
                    <li>The filmmaker must disclose information about the censor certification status at the time of submission of the film.</li>
                    <li>The Films/ Entries submitted by the applicants must constitute original work and not be an infringement of any other copyrighted work/s and further do not infringe the Intellectual Property Rights including the moral rights, privacy and publicity rights of any third party. None of the Intellectual Property Rights associated with the films those in literary, musical and artistic works should have been assigned/ licensed to any person, firm or corporation other than the Rights Holder.</li>
                </ul>

                <label>SUBMISSION, SELECTION AND SCREENING OF THE FILM:</label>
                <ul>
                    <li>Submission of film in this category means that the particular submitted film must not be released on any other public platform apart from other film festivals.</li>
                    <li>Submission of films does not guarantee selection for screening at PRIDPLEX OTT. The festival will not  provide feedback on films or share specific reasons for rejection of any film.</li>
                    <li>Once a film has been selected/ shortlisted it cannot be withdrawn from the competition.</li>
                    <li>The Primary contact Person will be notified about the selection of the film for PRIDEPLEX SHORT FILM COMPETITION – NE 2025 at the end of the selection process. No information will be divulged before that.</li>
                    <li>PRIDEPLEX SHORT FILM COMPETITION - NE 2025 accepts submissions via online application form.</li>
                    <li>
                        Films that are work-in-progress at the time of application are accepted under the following conditions:
                        <ul>
                            <ol>- That the submitted version will be the only version watched by the selection committee.</ol>
                        </ul>
                    </li>
                    <li>
                        Once selected for screening on Prideplex OTT, following materials to be submitted in soft copy on request to use in promotional material for the festival and the film:
                        <ul>
                            <ol>- High resolution stills (2-3) of the film.</ol>
                            <ol>- High resolution soft copy of the film poster.</ol>
                        </ul>

                        <p>Please note that we require the film to be played in its entirety and therefore require a link to a single video. Multiple links for the same film will not be accepted.</p>
                    </li>

                    <li>Team PSFC - NE is entitled to use any of the selected film's content for promotional, publicity and marketing material including posters, images, clips, trailer etc. across media in connection with the competition.</li>
                    <li>No requests for private screenings to selection committee members will be entertained. No film links sent via email without filing the application form will be considered for selection.</li>
                    <li>The film screener link must be active at least till the end of the festival. In case the online screener shared is not password protected, the competition cannot be held responsible for the security of the film screener.</li>
                    <li>In case the online screener shared is not password protected, the festival cannot be held responsible for the security of the film screener.</li>
                    <li>All online films will be destroyed/ deleted immediately after the selection process of the competition is completed.</li>
                </ul>

                <label>PAYMENT AND DEADLINE</label>
                <ul>
                    <li>The festival has a policy of no fee waivers</li>
                </ul>

                <label>ACKNOWLEDGEMENT:</label>
                <ul>
                    <li>I hereby declare and affirm that I have obtained necessary permission from the people who have made the film and own the rights to use the film's material for submitting it to the film festival.</li>
                    <li>I further declare and confirm that the film and its content do not break any copyright, patent, or other rights, and they don't go against any laws or rules.</li>
                    <li>I confirm that I haven't done anything or caused anything to happen that would cause me to give up or damage any rights, including but not limited to copyrights, permissions, or approvals, for the film and its content.</li>
                    <li>I agree that the film festival can edit or shorten the synopsis text as needed for publishing in the festival's catalogue.</li>
                    <li>I agree that the stills, posters, clips or other promotional materials of the film could be used as per the film festival's requirements to advertise and promote the film/ festival solely for the purpose of film festival.</li>
                    <li>I confirm that I have the rights to grant permission for use, and these rights are clear of any debts, obligations, or disputes. I also confirm that any necessary royalty or license fees are paid or will be paid by me, now or in the future.</li>
                    <li>I agree that PSFC - NE won't be held responsible for any harm or loss to my film or its content, like promotional materials or synopsis I share with the festival through uploading, posting, emailing, sending, or any other means.</li>
                </ul>

                <p>I confirm that, I have read, understood and agreed to abide by the rules and regulations for participating in the for PRIDEPLEX SHORT FILM COMPETITION - NE 2025</p>
            </div>
        </div>
    </div>
</div>