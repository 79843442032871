<div class="maincontentarea mt20">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="_contact_content" 
                    *ngIf="!isWebView">
                    <h2>Contests</h2>
                </div>
                <div class="contest-form-block">
                    <div class="contest-form"
                        *ngIf="!isUploading && !isUploadComplete && !isUploadFailed && !isFormSubmitFailed">
                        <form [formGroup]="contestForm" autocomplete="off" >
                            <h3>Fill out the Form to Participate</h3>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Primary Mobile No<span>*</span></label>
                                    <div class="phone-code" [ngClass]="{'phone-code-active': contestForm?.value?.mobile?.length > 0}">
                                        <span>+91-</span>
                                        <input autocomplete="new-mobile" class="form-control" type="text" formControlName="mobile"
                                        (keydown)="preventNonNumeric($event)" inputmode="numeric" maxlength="10" placeholder="Enter the mobile number" />
                                    </div>
                                    <div class="contest-error">
                                        <span *ngIf="isFormSubmitted && contestForm.controls.mobile.invalid && contestForm.controls.mobile.errors.required" class="invalid-feedback _require_filed">Enter Mobile Number</span>
                                        <span *ngIf="isFormSubmitted && contestForm.controls.mobile.invalid && !contestForm.controls.mobile.errors.required" class="invalid-feedback _require_filed">Enter 10 digit Mobile Number</span>
                                        <!-- <span *ngIf="isFormSubmitted && contestForm.controls.mobile.invalid && (contestForm.controls.mobile.errors.minlength || contestForm.controls.mobile.errors.minlength)" class="invalid-feedback _require_filed">Please enter a 10 digit Mobile Number</span> -->
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Secondary Mobile No<span>*</span></label>
                                    <div class="phone-code" [ngClass]="{'phone-code-active': contestForm?.value?.sec_mobile?.length > 0}">
                                        <span>+91-</span>
                                        <input autocomplete="new-mobile" class="form-control" type="text" formControlName="sec_mobile"
                                        (keydown)="preventNonNumeric($event)" inputmode="numeric" maxlength="10" placeholder="Enter the secondary mobile number" />
                                    </div>
                                    <div class="contest-error">
                                        <span *ngIf="isFormSubmitted && contestForm.controls.sec_mobile.invalid && contestForm.controls.sec_mobile.errors.required" class="invalid-feedback _require_filed">Enter secondary Mobile Number</span>
                                        <span *ngIf="isFormSubmitted && contestForm.controls.sec_mobile.invalid && !contestForm.controls.sec_mobile.errors.required" class="invalid-feedback _require_filed">Enter 10 digit Mobile Number</span>
                                        <!-- <span *ngIf="isFormSubmitted && contestForm.controls.mobile.invalid && (contestForm.controls.mobile.errors.minlength || contestForm.controls.mobile.errors.minlength)" class="invalid-feedback _require_filed">Please enter a 10 digit Mobile Number</span> -->
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Email Address<span>*</span></label>
                                    <input autocomplete="new-email" class="form-control" type="text" formControlName="email" placeholder="Enter your email address" />
                                    <div class="contest-error">
                                        <span *ngIf="isFormSubmitted && contestForm.controls.email.invalid && contestForm.controls.email.errors.required" class="invalid-feedback _require_filed">Enter Email Address</span>
                                        <span *ngIf="isFormSubmitted && contestForm.controls.email.invalid && contestForm.controls.email.errors.pattern" class="invalid-feedback _require_filed">Enter a valid Email Address</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Name/Title of the Short Film<span>*</span></label>
                                    <input autocomplete="new-email" class="form-control" type="text" formControlName="name" placeholder="Enter the name of the short film" />
                                    <div class="contest-error">
                                        <span *ngIf="isFormSubmitted && contestForm.controls.name.invalid" class="invalid-feedback _require_filed">Enter the name of the short film</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Language<span>*</span></label>
                                    <input autocomplete="new-name" class="form-control" type="text" formControlName="language" placeholder="Enter the language of the short film" />
                                    <div class="contest-error">
                                        <span *ngIf="isFormSubmitted && contestForm.controls.language.invalid" class="invalid-feedback _require_filed">Enter the language of the short film</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Name of the Director</label>
                                    <input autocomplete="new-address" class="form-control" type="text" formControlName="director" placeholder="Enter the name of the short film's director" />
                                    <div class="contest-error">
                                        <!-- <span *ngIf="isFormSubmitted && contestForm.controls.address.invalid" class="invalid-feedback _require_filed">Please enter a Address</span> -->
                                    </div>
                                </div>
                                <!-- <div class="col-md-12">
                                    <label>Description<span>*</span></label>
                                    <textarea autocomplete="new-text" class="form-control" rows="3" formControlName="description" placeholder="Describe about yourself"></textarea>
                                    <div class="contest-error">
                                        <span *ngIf="isFormSubmitted && contestForm.controls.description.invalid" class="invalid-feedback _require_filed">Please enter a description</span>
                                    </div>
                                </div> -->
                                <div class="col-md-12">
                                    <label>Name of the Production House</label>
                                    <input type="text" autocomplete="new-text" class="form-control" formControlName="production_house" placeholder="Enter the Name of Production House" />
                                    <div class="contest-error">
                                        <!-- <span *ngIf="isFormSubmitted && contestForm.controls.production_house.invalid" class="invalid-feedback _require_filed">Please enter a description</span> -->
                                    </div>
                                </div>
                                <div class="col-md-12 declaration-text">
                                    <h6 class="text-center mb-3">DECLARATION BY PRODUCER</h6>
                                    <p>I HERE BY DECLARE THAT THE PARTICULARS GIVEN BY ME IN THIS ENTRY FORM ARE TRUE & CORRECT TO THE BEST OF MY KNOWLEDGE.</p>
                                    <p>I PROMISE THAT THE SUBMITTED SHORT FILM IS OF OUR OWN CREATION & PRODUCED BY ME WITH THE HELP OF ABOVE-MENTIONED CREATIVE TEAM.</p>
                                    <p>WE WILL OBEY & ACCEPT ALL THE TERMS AND CONDITIONS OF THE COMPETITION BY PSFC-NE.</p>
                                    <p>WE WILL ACCEPT ALL THE STEPS FORMED BY PSFC - NE IN SCREENING & SELECTION PROCESS. WE UNDERSTAND & WILL ACCEPT THE FINAL VERDICT OF JUDGES OF THIS COMPETITION WE HERE BY AUTHORISE AND ACCEPT TO GIVE THE PROMOTION / TELECAST & DISSIMINATION RIGHTS OF THE SHORT FILM IN PRIDEPLEX BY PSFC - NE WITH OUT MAKING ANY CONDITION & OBJECTION AND WITH MUTUAL DISCUSSION ON FINACIAL MATTERS.</p>
                                </div>
                                <div class="col-md-12 declaration-text mb-4">
                                    <label class="contest-checkbox">
                                        <input type="checkbox" class="form-control" formControlName="declaration" />I agree to the terms, conditions, and declaration stated above.
                                    </label>
                                    <div class="contest-error">
                                        <span *ngIf="isFormSubmitted && contestForm.controls.declaration.invalid" class="invalid-feedback _require_filed">Accept the terms, conditions, and declaration before submission.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="file-upload-container">
                                        <label for="file-upload" class="add-files-button"><span>+</span> Add Files</label>
                                        <input 
                                            type="file" 
                                            id="file-upload" 
                                            class="file-upload-input" 
                                            (change)="onFileSelected($event)"
                                            accept="video/*"
                                        />
                                        <p class="file-info" *ngIf="selectedFile">{{ selectedFile.name }}</p>
                                        <p class="file-info" *ngIf="!selectedFile">(Only MP4 and MOV files up to 2GB are allowed.)</p>
                                        <div class="contest-error">
                                            <span *ngIf="isFormSubmitted && !selectedFile" class="invalid-feedback _require_filed">Add a file</span>
                                            <span *ngIf="fileError" class="invalid-feedback _require_filed">{{ fileError }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-right">
                                    <button class="default-btn-view mb10 mbl-block-btn" (click)="handleParticipateClick()">Participate Now</button>
                                    <!-- <div *ngIf="formError" class="invalid-feedback _require_filed"
                                        style="display: block !important;">
                                        <span class="_long_otp_login_1">{{ formError }}</span>
                                    </div> -->
                                </div>
                            </div>
                        </form>
                    </div>
                    <a id="openConfirmationModal" data-toggle="modal" data-target="#confirmContest" data-backdrop="static"></a>

                    <div class="contest-uploading text-center"
                        *ngIf="isUploading">
                        <div class="contest-success">
                            <h3 class="text-white">Submitting your details</h3>
                            <img class="upload-icon-align" src="../../../assets/img/Icon/file-upload.svg" />
                            <div class="progress-bar-container">
                                <div 
                                class="progress-bar" 
                                [style.width.%]="uploadProgress">
                                </div>
                            </div>
                            <p *ngIf="uploadProgress <= 100">{{ uploadProgress }}% Uploaded</p>
                            <p class="mt40">Do not close or reload this page, or submission may fail.</p>
                        </div>
                    </div>

                    <div class="contest-video-uploaded text-center mt40">
                        <div class="contest-success"
                            *ngIf="isUploadComplete">
                            <img src="../../../assets/img/Icon/confirm.svg" />
                            <h3 class="text-white mt40">Thank You!</h3>
                            <p>Your Submission has been sent</p>
                        </div>

                        <div class="contest-faild"
                            *ngIf="isFormSubmitFailed">
                            <img src="../../../assets/img/Icon/upload-failed.svg" />
                            <h3 class="text-white mt40 mb40">Oops! Form Submission Failed</h3>
                            <p>Something went wrong while submitting your form. Please try again. If the issue persists, contact support.</p>
                            <button class="default-btn-view mbl-block-btn" (click)="handleTryAgainClick()">Try Again</button>
                        </div>

                        <div class="contest-faild"
                            *ngIf="isUploadFailed">
                            <img src="../../../assets/img/Icon/upload-failed.svg" />
                            <h3 class="text-white mt40 mb40">Upload Failed</h3>
                            <p>Something went wrong while uploading your video. Please try again. If the issue persists, check your internet connection or contact support.</p>
                            <button class="default-btn-view mbl-block-btn" (click)="handleTryAgainClick()">Try Again</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal auth" id="confirmContest">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-left">Confirm Submission</h5>
      </div>
      <div class="modal-body">
        <p class="text-white" >
            Are you sure you want to submit the details? Once submitted, you may not be able to make changes.
        </p>
        <div class="text-right">
            <button class="default-btn-view mr-2" (click)="startUpload()" data-dismiss="modal" aria-label="Close">Submit</button>
            <button class="default-btn-view" data-dismiss="modal" aria-label="Close">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>