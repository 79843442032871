
<footer class="inner_f_i" *ngIf="!isVouchersPage && !isHoroscopePage && !isMandirPage && !isAppsPages">
    <div class="container">
        <!-- <div class="row">
            <div class="col-sm-12">
                <div class="inner_footer_social">
                    <ul>
                        <li>
                            <a href="javascript:void(0);">
                                <i class="fa fa-facebook-square" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <i class="fa fa-twitter-square" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <i class="fa fa-youtube-play" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>

                    <div class="inner_app">                 
                        <div class="app_list">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.digivive.prideplex" class="w_play_store"></a>
                            <a target="_blank" href="#" class="w_app_store"></a>
                        </div>
                     </div>
                </div>
  
                <div class="inner_footer_page">
                    <ul>
                        <li>
                            <a href="javascript:void(0);"  routerLink="/advertise">
                                Advertise
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/aboutus">
                                About Us
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/tnc">
                                Terms &  Use
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/privacy">
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/faq">
                             FAQs
                            </a>
                        </li>
                        
                        <li>
                            <a href="javascript:void(0);" routerLink="/media">
                                Media
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/contactus">
                                Contact Us
                            </a>
                        </li>
                        
                    </ul>
                </div>

                <span class="demo_c">This is demo website for testing purposes.</span>
                <p class="copy">&copy;  {{ currentYear }}, PridePlex is an OTT platform run by Pride East Entertainments Pvt Ltd</p>
            </div>
        </div> -->

        <div class="row mt-5">
            <div class="col-md-3 footer-nav">
                <label>Company</label>
                <ul>
                    <li><a href="javascript:;" routerLink="/aboutus">About Us</a></li>
                    <!-- <li><a href="javascript:;">News</a></li> -->
                </ul>
            </div>
            <div class="col-md-3 footer-nav">
                <label>Support</label>
                <ul>
                    <li><a href="javascript:;" routerLink="/contactus">Contact Us</a></li>
                    <li><a href="javascript:;" routerLink="/faq">FAQs</a></li>
                </ul>
            </div>
            <div class="col-md-3 footer-nav">
                <label>Legal</label>
                <ul>
                    <li><a href="javascript:;" routerLink="/tnc">Terms of Use</a></li>
                    <li><a href="javascript:;" routerLink="/privacy">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="col-md-3 footer-social">
                <img class="f-logo" src="../../../../assets/img/logo.png" />
                <!-- <ul>
                    <li><a href="javascript:;"><img src="../../../../assets/img/Icon/facebook.svg" /></a></li>
                    <li><a href="javascript:;"><img src="../../../../assets/img/Icon/instagram.svg" /></a></li>
                    <li><a href="javascript:;"><img src="../../../../assets/img/Icon/linkedin.svg" /></a></li>
                    <li><a href="javascript:;"><img src="../../../../assets/img/Icon/youtube.svg" /></a></li>
                </ul> -->
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-md-12 footer-bottom">
                <h6 class="text-white text-center">Available on many devices. Install the app now.</h6>
                <ul>
                    <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.digivive.prideplex"><img src="../../../../assets/img/Icon/google-play.svg" /></a></li>
                    <li><a target="_blank" href="https://apps.apple.com/us/app/prideplex/id6451878776"><img src="../../../../assets/img/Icon/app-store.svg" /></a></li>
                    <li><a target="_blank" href="https://play.google.com/store/apps/details?id=digivive.prideplex.androidtv"><img src="../../../../assets/img/Icon/android-tv.svg" /></a></li>
                    <li><a target="_blank" href="https://www.amazon.com/Pride-East-Media-Entertainment-pvt/dp/B0CFB5BYT9/ref=sr_1_1?dib=eyJ2IjoiMSJ9.qbTCQzqA9fvJw1VkZWEQhw.8AcP0i0ThHWQsKu3318dx567_MyApFHYaIrpueb_Il8&dib_tag=se&keywords=prideplex&qid=1727946019&s=mobile-apps&sr=1-1"><img src="../../../../assets/img/Icon/fire-tv.svg" /></a></li>
                </ul>
                <p>&copy; {{currentYear}}, PridePlex is an OTT platform run by Pride East Entertainments Pvt Ltd</p>
            </div>
        </div>
    </div>        
</footer>

<div style="background-image: url('../../../../assets/img/Icon/checkbox-check.svg');width: 1px;height: 1px;visibility: hidden;"></div>