import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContestService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
  ) { }

  getAllContests(): Observable<any> {
    return this.apiService.get('contest?action=get_contest_list').pipe(map(data => {
      return data;
    }
    ));
  }

  getContestDetail(contestId: string): Observable<any> {
    return this.apiService.get('contest?action=get_contest&contest_id=' + contestId).pipe(map(data => {
      return data;
    }
    ));
  }

  getContestDetailForForm(contestId, sessionId): Observable<any> {
    return this.http.get(`${environment.api_url}contest?action=get_contest&contest_id=${contestId}`,
      {
        headers: {
          'Authorization': sessionId,
        },
    }).pipe(
      catchError(this.handleError)
    );
  }

  getUserData(sessionId): Observable<any> {
    return this.http.get(`${environment.api_url}signupnexg/profile`,
      {
        headers: {
          'Authorization': sessionId,
        },
    }).pipe(
      catchError(this.handleError)
    );
  }

  uploadMediaInChunks(data, sessionId): Observable<any> {
    return this.http.post(`${environment.api_url}contest?action=uploadinchunks`,
      data,
      {
        headers: {
          'Authorization': sessionId,
        },
    }).pipe(
      catchError(this.handleError)
    );
  }

  postContestData(data, sessionId): Observable<any> {
    return this.http.post(`${environment.api_url}contest?action=save_contest`, data,{
      headers: {
        'Authorization': sessionId,
      },
    }).pipe(
      catchError(this.handleError)
    );
  }

  getFinalStatus(formId, sessionId): Observable<any> {
    return this.http.get(`${environment.api_url}contest?action=final_status&form_id=${formId}`,
      {
        headers: {
          'Authorization': sessionId,
        },
    }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`);
    }
    console.log(HttpErrorResponse);
    // return an observable with a user-facing error message
    return throwError(error.error);
  }
}
