import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContestService } from 'src/app/core/services/contest.service';

@Component({
  selector: 'app-contest-form',
  templateUrl: './contest-form.component.html',
  styleUrls: ['./contest-form.component.css']
})
export class ContestFormComponent implements OnInit {

  selectedFile: File;
  userData: any;
  uploadProgress: number = 0;
  isUploading: boolean;
  isUploadComplete: boolean;
  isUploadFailed: boolean;
  contestId: string;
  contestForm: FormGroup;
  isFormSubmitted: boolean;
  formError: string;
  isWebView: boolean;
  sessionId: string;
  fileError: string;
  isFormSubmitFailed: boolean;
  finalStatusCheckCount: number = 40;
  finalStatusInterval: any;
  isDeclarationChecked: boolean = false;

  constructor(
    private contestService: ContestService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    let emailPattern = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
    this.contestId = this.route.snapshot.paramMap.get('contestId') || '';
    this.isWebView = this.route.snapshot.queryParamMap.get('isWebView') === 'true';

    if (localStorage.getItem('userInfo')) {
      this.sessionId = localStorage.getItem('sessionId');
    } else {
      this.sessionId = this.route.snapshot.queryParamMap.get('sessionId') || localStorage.getItem('sessionId');
    }
    
    if (this.isWebView) {
      const header = document.querySelector('app-header');
      const footer = document.querySelector('app-footer');

      if (header) {
        this.renderer.setStyle(header, 'display', 'none');
      }
      if (footer) {
        this.renderer.setStyle(footer, 'display', 'none');
      }
    }
    this.contestForm = this.fb.group({
      'mobile': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10),Validators.pattern('^[0-9]*$')]],
      'sec_mobile': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10),Validators.pattern('^[0-9]*$')]],
      'email': ['', [Validators.required,Validators.email,Validators.pattern(emailPattern)]],
      'name': ['', [Validators.required]],
      'language': ['', [Validators.required]],
      'director': [''],
      'production_house': [''],
      'declaration': [false,  [Validators.requiredTrue]]
    });

    if (localStorage.getItem('userInfo')) {
      this.getContestData();
    }
    this.getUserData();
    
  }

  getContestData() {
    this.contestService.getContestDetailForForm(this.contestId, this.sessionId).subscribe(
      (contestRes) => {
        if (contestRes.result.is_applied == 1) {
          console.log('You have already filled the form');
          this.router.navigateByUrl('/contest-detail/' + this.contestId);
        }
      },
      (error) => {
        console.error('error fetching contest data: ', error);
      }
    );
  }

  getUserData() {
    this.contestService.getUserData(this.sessionId).subscribe(
      (userDataRes) => {
        this.userData = userDataRes.result;
        this.contestForm.get('email').setValue(this.userData.emailaddress);
        this.contestForm.get('mobile').setValue(this.userData.mobile);
        // this.contestForm.get('name').setValue(this.userData.name);
      },
      (error) => {
        console.error('error fetching user data: ', error);
      }
  );
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const file = input.files[0];
      const allowedFileTypes = ['video/mp4', 'video/mov', 'video/quicktime'];
      const maxFileSize = 2 * 1024 * 1024 * 1024; // 2 GB in bytes

      if (!allowedFileTypes.includes(file.type)) {
        this.fileError = 'Invalid file type. Only MP4 and MOV files are allowed.';
        this.selectedFile = null; // Reset the selected file
        return;
      }

      if (file.size > maxFileSize) {
        this.fileError = 'File size exceeds the 2 GB limit.';
        this.selectedFile = null; // Reset the selected file
        return;
      }

      this.selectedFile = file;
      this.fileError = '';
    }
  }


  startUpload(): void {
    this.isFormSubmitted = true;
    this.formError = '';
  
    if (this.contestForm.invalid) {
      console.error('contest form invalid: ', this.contestForm);
      this.formError = 'Please fill the form properly';
      return;
    }
  
    if (!this.selectedFile) {
      console.error('No file selected');
      this.formError = 'Please select a file';
      return;
    }
  
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    const chunkSize = 5 * 1024 * 1024; // 5 MB per chunk
    const totalChunks = Math.ceil(this.selectedFile.size / chunkSize);
    const timestamp = Date.now().toString();
    let currentChunk = 0;
    this.isUploading = true;
    this.uploadProgress = 0;
  
    const uploadChunk = () => {
      const start = currentChunk * chunkSize;
      const end = Math.min(start + chunkSize, this.selectedFile.size);
      const chunk = this.selectedFile.slice(start, end);
      console.log(`uploading chunk ${currentChunk + 1}: `, chunk);
  
      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('contest_id', this.contestId);
      formData.append('chunk', currentChunk.toString());
      formData.append('chunks', totalChunks.toString());
      formData.append('name', this.selectedFile.name);
      formData.append('timestamp', timestamp);
    
      const uploadTimeout = 3 * 60 * 1000; // 3 minute timeout for chunk upload 
      let timeoutHandler: any;
    
      const uploadSubscription = this.contestService.uploadMediaInChunks(formData, this.sessionId).subscribe(
        (uploadRes) => {
          clearTimeout(timeoutHandler); // Clear timeout if request succeeds
          console.log(`Chunk ${currentChunk + 1}/${totalChunks} uploaded successfully`);
    
          if (uploadRes.status === 'success') {
            currentChunk++;
            this.uploadProgress = Math.min(Math.floor((currentChunk / totalChunks) * 100), 99);
    
            if (currentChunk < totalChunks) {
              uploadChunk(); // Upload next chunk
            } else {
              console.log('All chunks uploaded successfully', uploadRes.data.media_id);
              this.saveContestForm(uploadRes.data.media_id);
            }
          } else {
            console.error('Chunk upload failed: ', uploadRes.error_code);
            this.formError = 'Failed to upload chunks';
            this.isUploading = false;
            this.isUploadFailed = true;
          }
        },
        (error) => {
          clearTimeout(timeoutHandler);
          console.error('Error uploading chunk: ', error);
          this.formError = 'Failed to upload chunks';
          this.isUploading = false;
          this.isUploadFailed = true;
        }
      );
    
      // Set timeout to cancel request if it takes too long
      timeoutHandler = setTimeout(() => {
        console.error(`Chunk ${currentChunk + 1} upload timed out.`);
        uploadSubscription.unsubscribe();
        this.formError = 'Upload timed out, please retry.';
        this.isUploading = false;
        this.isUploadFailed = true;
      }, uploadTimeout);
    };
  
    uploadChunk(); // Start uploading chunks
  }

  saveContestForm(mediaId: string) {
    const formData = new FormData();
    formData.append("contest_id", this.contestId);
    formData.append("name", this.contestForm.get('name')?.value);
    formData.append("email", this.contestForm.get('email')?.value);
    formData.append("mobile", this.contestForm.get('mobile')?.value); // this.contestForm.get('mobile')?.value
    formData.append("properties[sec_mobile]", this.contestForm.get('sec_mobile')?.value);
    formData.append("properties[language]", this.contestForm.get('language')?.value);
    formData.append("properties[director]", this.contestForm.get('director')?.value);
    formData.append("properties[production_house]", this.contestForm.get('production_house')?.value);
    formData.append("properties[declaration]", (this.contestForm.get('declaration')?.value ? '1' : '0'));
    formData.append('media_id', mediaId);
  
    this.contestService.postContestData(formData, this.sessionId).subscribe(
      (res) => {
        if (res.error_code == 200) {
          console.log('Contest data saved successfully');
          // this.isUploading = false;
          // this.isUploadComplete = true;

          this.finalizeUpload(res.result.form_id);
        } else {
          console.error('Error saving contest data: ', res.error_string);
          this.isUploading = false;
          this.isFormSubmitFailed = true;
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
        }
      },
      (error) => {
        console.error('Error saving contest data: ', error);
        this.isUploading = false;
        this.isFormSubmitFailed = true;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
      }
    );
  }
  
  finalizeUpload(formId: string): void {
    this.isFormSubmitFailed = false;
    let checkStatusCount = this.finalStatusCheckCount;
  
    const checkStatus = () => {
      if (checkStatusCount <= 0) {
        clearInterval(this.finalStatusInterval);
        this.isUploading = false;
        this.isFormSubmitFailed = true; // Mark as failed if no final status received
        console.error('Final status check limit reached.');
        return;
      }
  
      checkStatusCount--;
  
      this.contestService.getFinalStatus(formId, this.sessionId).subscribe(
        (finalStatusRes) => {
          if (finalStatusRes.error_code == 200 && finalStatusRes.result.success == '1') {
            clearInterval(this.finalStatusInterval);
            this.uploadProgress = 100;
            setTimeout(() => {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
              this.isUploading = false;
              this.isUploadComplete = true;
              console.log('Final status received:', finalStatusRes.result);
              // Navigate or take further action
              if (!this.isWebView) {
                setTimeout(() => {
                  this.router.navigateByUrl('/contest-detail/' + this.contestId);
                }, 3000);
              }
            }, 500);
          } else {
            console.log('Final status not ready, retrying...');
          }
        },
        (error) => {
          console.error('Error checking final status:', error);
          this.isFormSubmitFailed = true;
          clearInterval(this.finalStatusInterval);
        }
      );
    };
  
    // Execute the first request immediately
    checkStatus();
  
    // Set an interval to execute every 5 seconds for subsequent requests
    this.finalStatusInterval = setInterval(checkStatus, 5000);
  }
  
  

  handleParticipateClick() {
    this.isFormSubmitted = true;
    this.formError = '';
    console.log(this.contestForm);
    for (const key in this.contestForm.controls) {
      if (this.contestForm.controls[key].invalid) {
        console.error(`Invalid field: ${key}`);

        const invalidControl = document.querySelector(`[formControlName="${key}"]`);
        if (invalidControl) {
          (invalidControl as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        this.formError = 'Please fill the form properly';
        return;
      }
    }
    if (this.contestForm.invalid || !this.selectedFile) {
      console.error('contest form invalid: ', this.contestForm);
      this.formError = 'Please fill the form properly';
      return;
    }
    document.getElementById('openConfirmationModal')?.click();
  }

  handleTryAgainClick() {
    this.isUploadFailed = false;
    this.isUploadComplete = false;
    this.isUploading = false;
    this.isFormSubmitFailed = false;
    this.isFormSubmitted = false;
    // this.contestForm.reset();
    this.selectedFile = null;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control',]; //  'V', 'v'
    if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

}
