import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contest-rules',
  templateUrl: './contest-rules.component.html',
  styleUrls: ['./contest-rules.component.css']
})
export class ContestRulesComponent implements OnInit {

  isWebView: boolean;

  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.isWebView = this.route.snapshot.queryParamMap.get('isWebView') === 'true';

    if (this.isWebView) {
      const header = document.querySelector('app-header');
      const footer = document.querySelector('app-footer');

      if (header) {
        this.renderer.setStyle(header, 'display', 'none');
      }
      if (footer) {
        this.renderer.setStyle(footer, 'display', 'none');
      }
    }
  }

}
